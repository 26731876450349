import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Statistic,
  Table,
  Button,
  Collapse,
  Modal,
  Descriptions,
  Tag,
  Image,
  Divider,
  Result,
} from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import { cardTerminals, PostponeReasons } from "../../Components/common/constants";
import { ExpandAltOutlined, EyeFilled } from "@ant-design/icons";
// import { config.netsuite_url,  } from "../../Components/config";
import config, { env, tms_uploads_cdn }  from "../../Components/config";

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    "& .ant-card .ant-card-body": {
      padding: "4px",
      // backgroundColor: 'rgb(240, 240, 240)',
    },
    
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .ant-input[disabled]": {
      color: 'black !important',
      fontWeight: 500
    },
    
  },
}));


const DriverManifestReport = ({ driverManifestData, setDriverManifestData, paymentAuthorizer }) => {
  const classes = useStyles();
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState("");


  const handleFilesOpen = (row) => {
    console.log("row", row);
    if (row) {
      setSelectedRecord(row);
    }
    setFilesOpen(true);
  };
  const handleFilesClose = () => setFilesOpen(false);


  const generateColor = (index) => {
    const colors = ["#f0f0f0"];
    return colors[0];
  };
  const { Panel } = Collapse;


  const dataCol = [
    {
      title: "S.No.",
      dataIndex: "rank",
      key: "rank",
      width: 55
    }, 
    {
      title: "Shop Code",
      render: (text,record) => {
        return <span><a href={`${config.netsuite_url}/app/common/entity/custjob.nl?id=${record.customerInternalId}`} target="_blank">{record.customerName}</a></span>;
      },
      width: 100
    },
    {
      title: "Shop Name",
      dataIndex: "customerProjectId",
      key: "customerProjectId",
      width: 200
    },
    {
      title: "Invoice",
      render: (text, record) => {
        return <span><a href={`${config.netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.invoiceInternalId}`} target="_blank">{record.invoice_no}</a></span>;
      },
      width: 100
    },
    {
      title: "Address",
      dataIndex: "ship_address1",
      key: "ship_address1",
      width: 200
    },
    {
      title: "Post Code",
      dataIndex: "ship_zip",
      key: "ship_zip",
      width: 100
    },
    {
      title: "Terms",
      dataIndex: "terms",
      key: "terms",
      width: 100
    },
    {
      title: "Total (£)",
      dataIndex: "orderAmount",
      key: "orderAmount",
      render: (text, record) => Number(record.orderAmount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00',
      width: 100
    },
    {
      title: "Cash",
      dataIndex: "amountReceivedCash",
      key: "amountReceivedCash",
      render: (text, record) => Number(record.amountReceivedCash).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00',
      width: 80
    },
    {
      title: "Card",
      dataIndex: "amountReceivedCard",
      key: "amountReceivedCard",
      render: (text, record) => Number(record.amountReceivedCard).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00',
      width: 80
    },
    {
      title: "Bank",
      dataIndex: "amountReceivedAccount",
      key: "amountReceivedAccount",
      render: (text, record) => Number(record.amountReceivedAccount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00',
      width: 80
    },
    {
      title: "Status",
      render(record) {
        let statusText;
        let backgroundColor;
    
        switch (record?.delivered) {
          case 1:
            statusText = "Delivered";
            backgroundColor = "#80cb80";
            break;
          case 2:
            statusText = "Postponed";
            backgroundColor = "#f0d787";
            break;
          default:
            statusText = "Not Delivered";
            backgroundColor = "#edb1a7";
            break;
        }
    
        return {
          props: {
            style: {
              background: backgroundColor,
            },
          },
          children: <>{statusText}</>,
        };
      },
      width: 100
    },   
    {
      title: "Action",
      align: "center",
      width: 75,
      render(record) {
        return (
          <EyeFilled
            style={{ cursor: "pointer", fontSize: "25px" }}
            onClick={() => {
              handleFilesOpen(record);
            }}
          />
        );
      },
    },
  ];

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return <Tag color="warning">Pending</Tag>;
      case 2:
        return <Tag color="success">Approved</Tag>;
      case 3:
        return <Tag color="error">Rejected</Tag>;
      default:
        return "";
    }
  };


  const [expandAll, setExpandAll] = useState(false);

  const onClickExpand = () => {
    let data1 = [...driverManifestData];

    if (expandAll) {
      data1.map((route, rIdx) => {
            route.expanded = false;
        })
    }
    else {
      data1.map((route, rIdx) => {
            route.expanded = true;
        });
    }
    setDriverManifestData(data1);
    setExpandAll(!expandAll);
};

const handleRouteExpand = (route_idx) => {
  const updatedData = [...driverManifestData];
  updatedData[route_idx].expanded = !updatedData[route_idx].expanded;
  setDriverManifestData(updatedData);
}

useEffect(() => {
  if (driverManifestData.length === 1) {
    setDriverManifestData(prevData => {
      return prevData.map(item => ({
        ...item,
        expanded: true
      }));
    });
  }
}, []);

console.log('driverManifestData', driverManifestData);


  return (
    <div className={classes.dataSheet}>
      <div style={{display: "flex", alignItems: 'center', justifyContent: 'space-between'}}>
      <h3>Driver Manifest</h3>
      <Button 
      type="primary" 
      style={{ float: 'right'}} 
      onClick={onClickExpand}
      icon={<ExpandAltOutlined />}>
          {expandAll ? "Collapse All" : "Expand All"}
        </Button>
      </div>
      {driverManifestData.length > 0 &&
        driverManifestData.map((data, index) => (
          <>
            <div className="view-page">
            <Collapse style={{margin: '10px 0px'}} activeKey={data.expanded ? index.toString() : ''} onChange={() => { handleRouteExpand(index)}}>
              <Panel header={
              <>
              <span style={{ fontWeight: 'bold'}}>Route: </span>
              <span style={{ marginRight: '10px'}}>{data.driverRoute}</span>
              <span style={{ fontWeight: 'bold'}}>Driver: </span>
              <span style={{ marginRight: '10px'}}>{data.driver}</span>
              <span style={{ fontWeight: 'bold'}}>Vehicle Reg: </span>
              <span >{data.truck}</span>
              </>
              }  key={index}>
              <Row gutter={[6, 16]}>
                <Col span={3}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Route No"
                        value={data.route}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={3}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Truck"
                        value={data.truck}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Route"
                        value={data.driverRoute}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Driver"
                        value={data.driver}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="CC Terminal No."
                        value={
                          data.terminal
                            ? cardTerminals.filter((obj) => obj.id == data.terminal)[0].title
                            : "N/A"
                        }
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[6, 16]} style={{ marginTop: "10px" }}>
                <Col span={4}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Total Cash Expected"
                        value={data.totalCash}
                        precision={2}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                        prefix="£"
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Total Card Expected"
                        value={data.totalCard}
                        precision={2}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                        prefix="£"
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={3}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Total Bank"
                        value={data.totalAccount}
                        precision={2}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                        prefix="£"
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <>
                <Table
                  style={{ overflowX: "auto", marginTop: "10px" }}
                  columns={dataCol}
                  bordered
                  dataSource={data.tableData}
                  pagination={false}
                  scroll={{
                    y: 400,
                  }}
                />
              </>

              </Panel>
              </Collapse>
              
            </div>
          </>
        ))}
        <Modal
        open={filesOpen}
        onCancel={handleFilesClose}
        okButtonProps={{ style: { display: "none" } }}
        title="Order Details"
        cancelText="Close"
        width={900}
      >
        <>
  {selectedRecord &&
  (
    selectedRecord.postponed_status ||
    (selectedRecord.postponed_files && selectedRecord.postponed_files.length > 0) ||
    selectedRecord.postponed_notes ||
    selectedRecord.received_by ||
    selectedRecord.notes ||
    selectedRecord.delivery_discount_req_status ||
    selectedRecord.delivery_discount_auth_by ||
    selectedRecord.payment_req_status ||
    selectedRecord.payment_auth_by ||
    selectedRecord.signature ||
    (selectedRecord.files && selectedRecord.files.length > 0)
  ) ? (
    <>
      {selectedRecord?.postponed_status ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Postponement Status"
            labelStyle={{ width: "200px" }}
          >
            {PostponeReasons.find((obj) => obj.id === selectedRecord?.postponed_status)?.title || ""}
          </Descriptions.Item>
        </Descriptions>
       ) : null}

      {selectedRecord?.postponed_notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Postpone Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.postponed_notes || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.received_by && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Receiver Name"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord.received_by || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.notes || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.delivery_discount_req_status && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Discount"
            labelStyle={{ width: "200px" }}
          >
            {getStatusText(selectedRecord.delivery_discount_req_status)}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.delivery_discount_auth_by && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Auth By"
            labelStyle={{ width: "200px" }}
          >
            {(() => {
              const authorizer = paymentAuthorizer?.find(
                (obj) =>
                  obj?.id?.toString() ===
                  selectedRecord?.delivery_discount_auth_by?.toString()
              );
              return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
            })()}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.payment_req_status && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Discount"
            labelStyle={{ width: "200px" }}
          >
            {getStatusText(selectedRecord.payment_req_status)}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.payment_auth_by > 0 && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Auth By"
            labelStyle={{ width: "200px" }}
          >
            {(() => {
              const authorizer = paymentAuthorizer?.find(
                (obj) => obj?.id === selectedRecord?.payment_auth_by
              );
              return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
            })()}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.signature != null && (
        <>
          <h4>Signature:</h4>
          <div
            style={{
              position: "relative",
              marginRight: "10px",
              display: "inline-block",
            }}
          >
            <Image
              width={150}
              height={100}
              style={{ padding: "5px", border: "1px solid lightgrey" }}
              src={selectedRecord?.signature}
            />
          </div>
        </>
      )}

      <Divider />

      {selectedRecord?.files?.length > 0 && (
        <>
          <h4>Files</h4>
          <Image.PreviewGroup>
            {selectedRecord.files.map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/route_orders/${selectedRecord?.routeStatusID}/${item}`;
              return (
                <div
                  key={item}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                </div>
              );
            })}
          </Image.PreviewGroup>
        </>
      )}
      {selectedRecord?.postponed_files?.length > 0 && (
        <>
          <h4>Postpone Files</h4>
          <Image.PreviewGroup>
            {selectedRecord.postponed_files.map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/postpone_order/${selectedRecord?.routeStatusID}/${item}`;
              return (
                <div
                  key={item}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                </div>
              );
            })}
          </Image.PreviewGroup>
        </>
      )}
    </>
  ) : (
    <Result status="404" subTitle="Sorry, no data data to show." />
  )}
</>

      </Modal>
    </div>
  );
};

export default DriverManifestReport;
