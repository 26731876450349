import {
  ClockCircleOutlined,
  EyeFilled,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Progress, Table, Tag } from "antd";
import React from "react";
import { formatWeight } from "../../Components/common/CommonFun";
import { schedules } from "../../Components/common/constants";

const RoutesTable = ({ onView, data, total, loading }) => {
  const getSchedule = (id) => {
    return schedules?.find((v) => v.id == id)?.title;
  };

  const getProgress = (total, completed) => {
    if (total === 0) return 0;
    const percentage = (completed / total) * 100;
    return Math.round(percentage);
  };

  const columns = [
    {
      title: (
        <div className="flex">
          <span>Route</span>
          <Tag color="#2db7f5">{total?.routes ?? 0}</Tag>
        </div>
      ),
      dataIndex: "title",
      key: "title",
      width: 150,
      render: (_, record) => {
        return <span>{`${record.title} (${record?.code})`}</span>;
      },
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle",
      key: "vehicle",
      width: 100,
    },
    {
      title: "Driver",
      dataIndex: "driver_name",
      key: "driver_name",
      width: 250,
      render: (driver) => (
        <Tag
          color="cyan"
          style={{
            display: "inline-flex",
            alignItems: "center",
            paddingBlock: 2,
          }}
        >
          <Avatar className="driver-avatar" size={20} icon={<UserOutlined />} />
          {driver}
        </Tag>
      ),
    },
    {
      title: "Schedule",
      dataIndex: "schedule_id",
      key: "schedule_id",
      width: 100,
      render: (i) => (
        <Tag color="blue" icon={<ClockCircleOutlined />}>
          {getSchedule(i)}
        </Tag>
      ),
    },
    {
      title: (
        <div className="flex">
          <span>Orders</span>
          <Tag color="#f50">{total?.orders ?? 0}</Tag>
        </div>
      ),
      dataIndex: "total_orders",
      key: "total_orders",
      width: 100,
    },
    {
      title: (
        <div className="flex">
          <span>Weight</span>
          <Tag color="#108ee9">{formatWeight(total?.weight ?? 0)}</Tag>
        </div>
      ),
      dataIndex: "order_weight",
      key: "order_weight",
      width: 140,
      render: (weight) => {
        return <span>{formatWeight(weight)}</span>;
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      width: 200,
      render: (_, pct) => {
        const percentage = getProgress(
          pct?.total_orders,
          pct?.completed_orders
        );
        return (
          <Progress
            percent={percentage}
            status="active"
            size={["100%", 10]}
            strokeColor={
              percentage < 50
                ? "#f5222d"
                : percentage < 75
                ? "#faad14"
                : "#52c41a"
            }
          />
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 100,
      render: (_, record) => {
        return (
          <Button
            variant="filled"
            color="primary"
            shape="circle"
            className="action-btn"
            onClick={() => onView(record)}
          >
            <EyeFilled />
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ y: 400 }}
      loading={loading}
    />
  );
};

export default RoutesTable;
