import { Modal, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
  applyStatusColor,
  formatCurrency,
  formatWeight,
} from "../../Components/common/CommonFun";
import { Link } from "react-router-dom";
import AuthService from "../../Components/auth/AuthService";
import { netsuite_url } from "../../Components/config";

const RouteDetail = ({ isModalOpen, routeDetail, handleCancel }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const Auth = new AuthService();

  const getRoutesData = async (id) => {
    try {
      setLoading(true);
      const res = await Auth.fetch(
        `/dashboard/routes-details/?route_status_id=${id}`
      );
      if (res) {
        setData(res?.route_details);
        setLoading(false);
      }
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRoutesData(routeDetail?.route_status_id);
  }, [routeDetail]);

  const columns = [
    {
      title: "Drop",
      dataIndex: "rank",
      key: "rank",
      width: 50,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_project_id",
      key: "customer_project_id",
      width: 200,
    },
    {
      title: "Shop Code",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 100,
      render: (shop, record) => {
        return (
          <Link
            to={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.invoice_no}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {shop}
          </Link>
        );
      },
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_no",
      key: "invoice_no",
      width: 100,
      render: (invoice, record) => {
        return (
          <Link
            to={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.customer_internal_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {invoice}
          </Link>
        );
      },
    },
    {
      title: "Order No.",
      dataIndex: "sale_order_no",
      key: "sale_order_no",
      width: 100,
      render: (text, record) => {
        return (
          <Link
            to={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${record.internal_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: "Order Value",
      dataIndex: "order_value",
      key: "order_value",
      width: 100,
      align: "end",
      render: (amount) => {
        return formatCurrency(amount);
      },
    },
    {
      title: "Cash",
      dataIndex: "amount_received_cash",
      key: "amount_received_cash",
      width: 100,
      align: "end",
      render: (cash) => {
        return <span>{cash ?? "-"}</span>;
      },
    },
    {
      title: "Card",
      dataIndex: "amount_received_card",
      key: "amount_received_card",
      width: 100,
      align: "end",
      render: (card) => {
        return <span>{card ?? "-"}</span>;
      },
    },
    // {
    //   title: "Postcode",
    //   dataIndex: "ship_zip",
    //   key: "ship_zip",
    //   width: 100,
    // },
    // {
    //   title: "Address",
    //   dataIndex: "ship_address1",
    //   key: "ship_address1",
    //   width: 200,
    // },
    // {
    //   title: "Terms",
    //   dataIndex: "terms",
    //   key: "terms",
    //   width: 200,
    // },
    {
      title: "Weight",
      dataIndex: "order_weight",
      key: "order_weight",
      width: 100,
      align: "end",
      render: (weight) => {
        return formatWeight(weight);
      },
    },
    {
      title: "Status",
      dataIndex: "delivered",
      key: "delivered",
      width: 100,
      align: "end",
      render: (val) => {
        const status =
          val === 1 ? "Delivered" : val === 2 ? "Postponed" : "Not Delivered";
        return <Tag color={applyStatusColor(status)}>{status}</Tag>;
      },
    },
  ];

  return (
    <Modal
      width={1200}
      open={isModalOpen}
      onCancel={handleCancel}
      title={`${routeDetail.title}  (${routeDetail.code})`}
      footer={false}
    >
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        size="large"
        loading={loading}
        scroll={{ y: 500 }}
        bordered
      />
    </Modal>
  );
};

export default RouteDetail;
