import { Card, Col, Divider, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as DragIcon } from "../../assets/images/icons/drag-icon.svg";
import tempData from "../../Components/common/mock.json";
import "./DashboardMain.css";
import DonutChart from "../../Components/Charts/DonutChart";
import BarChart from "../../Components/Charts/BarChart";
import RouteDetail from "./RouteDetail";
import RoutesTable from "./RoutesTable";
import Statistic from "./Statistic";
import _ from "lodash";
import AuthService from "../../Components/auth/AuthService";
import dayjs from "dayjs";

const Home = () => {
  const { weightData } = tempData;
  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false);
  const [routeDetail, setRouteDetail] = useState({});
  const [routesData, setRoutesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableVehicleLoading, setAvailableVehicleLoading] = useState(0);
  const [availableVehicle, setAvailableVehicle] = useState(0);
  const [routeHistory, setRouteHistory] = useState({});
  const [routeHistoryLoading, setRouteHistoryLoading] = useState(false);

  const [dashboardSequence, setDashboardSequence] = useState({});

  const [currentDate] = useState(dayjs().format("YYYY-MM-DD"));

  const Auth = new AuthService();

  const getRoutesData = async (date) => {
    try {
      setLoading(true);
      const res = await Auth.post("/dashboard/routes", {
        delivery_date: date,
      });
      if (res) {
        setRoutesData(res.routes);
        setLoading(false);
      }
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getVehicles = async (date) => {
    try {
      setAvailableVehicleLoading(true);
      const res = await Auth.fetch(
        `/dashboard/available-vehicles/?delivery_date=${date}`
      );
      if (res) {
        setAvailableVehicle(res?.available_vehicles[0]?.available_vehicles);
        setAvailableVehicleLoading(false);
      }
    } catch (error) {
      console.log(" error:", error);
      setAvailableVehicleLoading(false);
    } finally {
      setAvailableVehicleLoading(false);
    }
  };

  const getRouteHistory = async (date) => {
    try {
      setRouteHistoryLoading(true);
      const res = await Auth.fetch(
        `/dashboard/route-history/?delivery_date=${date}`
      );

      if (res) {
        const weightData = [];
        const orderData = [];
        const routeData = [];
        const revenueData = [];
        const categories = [];

        res?.route_history?.forEach((item) => {
          categories.push(item.week_day);
          weightData.push((item.order_weight / 1000).toFixed(2));
          orderData.push(item.total_orders);
          routeData.push(item.total_routes);
          revenueData.push((item.order_value / 1000).toFixed(2));
        });

        setRouteHistory({
          weightData,
          orderData,
          routeData,
          revenueData,
          categories,
        });
        setRouteHistoryLoading(false);
      }
    } catch (error) {
      console.log(" error:", error);
      setRouteHistoryLoading(false);
    } finally {
      setRouteHistoryLoading(false);
    }
  };

  // const userPreference = async () => {
  //   try {
  //     const res = await Auth.fetch("/dashboard/dashboard");

  //     if (res) {
  //       const parseData = JSON.parse(res.dashboard.dashboard_json);
  //       setDashboardSequence(parseData);
  //     }
  //   } catch (err) {
  //     console.log("Error", err);
  //   }
  // };

  const totals = routesData?.reduce(
    (acc, route) => {
      acc.weight += route.order_weight;
      acc.orders += route.total_orders;
      acc.revenue += route.order_value;
      acc.postponed += route.postponed_orders;
      acc.completed += route.completed_orders;
      acc.delivered_weight += route.delivered_weight;
      acc.order_value_delivered += route.order_value_delivered;
      acc.routes += 1;
      return acc;
    },
    {
      weight: 0,
      orders: 0,
      routes: 0,
      revenue: 0,
      postponed: 0,
      completed: 0,
      delivered_weight: 0,
      order_value_delivered: 0,
    }
  );

  const chartItems = [
    {
      id: "totalRevenue",
      title: "Total Revenue",
      component: DonutChart,
      data: [
        totals?.order_value_delivered,
        totals?.revenue - totals?.order_value_delivered,
      ],
      labels: weightData.map((el) => el.name),
      total: totals?.revenue,
      legendPostfix: "currency",
    },
    {
      id: "totalOrders",
      title: "Total Orders",
      component: DonutChart,
      data: [
        totals?.completed || 0,
        totals?.postponed || 0,
        totals?.orders - (totals?.completed + totals?.postponed) || 0,
      ],
      labels: ["Delivered", "Postponed", "Not Delivered"],
      total: totals?.orders,
    },
    {
      id: "totalWeight",
      title: "Total Weight",
      component: DonutChart,
      data: [
        totals?.delivered_weight,
        totals?.weight - totals?.delivered_weight,
      ],
      labels: weightData.map((el) => el.name),
      total: totals?.weight,
      legendPostfix: "kg",
    },
    {
      id: "availableVehicles",
      title: "Routes History",
      component: BarChart,
      data: routeHistory,
    },
  ];

  useEffect(() => {
    // userPreference();
    getRoutesData("2024-11-01");
    getRouteHistory("2024-11-01");
    getVehicles("2024-11-01");
  }, []);

  const renderChartCard = ({ title, component: Component, ...props }) => (
    <Card title={title} bordered extra={<DragIcon />}>
      {(loading && props.id !== "availableVehicles") ||
      (routeHistoryLoading && props.id === "availableVehicles") ? (
        <Spin className="chart-loader" />
      ) : (
        <Component {...props} />
      )}
    </Card>
  );

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedItems = Array.from(chartItems);
    const [moved] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, moved);

    reorderedItems.forEach((item, index) => {
      item.order = index + 1;
    });
  };

  const handleRouteClick = (routeData) => {
    setIsRouteModalOpen(true);
    setRouteDetail(routeData);
  };

  return (
    <div className="dashboard-main">
      <Statistic
        total={totals}
        loading={loading}
        vehicleLoading={availableVehicleLoading}
        totalVehicle={availableVehicle}
      />
      <Divider />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-row-2" direction="horizontal">
          {(provided) => (
            <Row
              gutter={16}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {_.sortBy(chartItems, "order").map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <Col
                      span={item.id === "availableVehicles" ? 24 : 8}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {renderChartCard(item)}
                    </Col>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Row>
          )}
        </Droppable>
      </DragDropContext>

      <Divider />
      <Row>
        <Col span={24}>
          <Card title="Routes" bordered>
            <RoutesTable
              data={routesData}
              total={totals}
              onView={handleRouteClick}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>

      {isRouteModalOpen && (
        <RouteDetail
          isModalOpen={isRouteModalOpen}
          handleCancel={() => setIsRouteModalOpen(false)}
          routeDetail={routeDetail}
        />
      )}
    </div>
  );
};

export default Home;
