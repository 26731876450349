import { useState, useEffect } from 'react';

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return width;
};
export default useWindowWidth;

export const formatWeight = (weight, locale = "en-GB") => {
  const value =
    typeof weight === "number"
      ? weight
      : Number(weight?.replace(/[^\d.-]/g, ""));

  const roundedValue = value.toFixed(2);
  return new Intl.NumberFormat(locale, {
    style: "unit",
    unit: "kilogram",
  }).format(roundedValue);
};

export const formatCurrency = (amount, locale = "en-GB", currency = "GBP") => {
  const value =
    typeof amount === "number"
      ? amount
      : Number(amount?.replace(/[^\d.-]/g, ""));
  const roundedValue = value.toFixed(2);
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(roundedValue);
};

export const applyStatusColor = (status) => {
  switch (status) {
    case "Delivered":
      return "#4099FF";
    case "Postponed":
      return "#FFB64D";
    case "Not Delivered":
      return "#FB4A3F";
    default:
      return "";
  }
};
