import Chart from "react-apexcharts";
import { formatCurrency, formatWeight } from "../../common/CommonFun";

const DonutChart = ({ data, labels, total, legendPostfix }) => {
  const calculatePercentage = (completed, total) => {
    if (total === 0) return "0%";
    return `${Math.round((completed / total) * 100)}%`;
  };

  const allZero = data?.every((value) => value === 0);

  const adjustedData = allZero ? [1] : data;
  const adjustedLabels = allZero ? ["No Data"] : labels;

  const statusColors = {
    Delivered: "#4099FF",
    "Not Delivered": "#FB4A3F",
    Postponed: "#FFB64D",
  };
  const colors = adjustedLabels?.map((label) => statusColors[label]);

  const postfix = legendPostfix || "";

  const chartOptions = {
    toolbar: {
      show: false,
    },
    chart: {
      type: "donut",
    },
    labels: adjustedLabels,
    colors: allZero ? ["#8c8c8c"] : colors,
    plotOptions: {
      pie: {
        donut: {
          width: "5%",
          size: "60%",
          labels: {
            show: false,
            total: {
              show: true,
              color: "var(--text-color)",
              formatter: () => {
                return calculatePercentage(Number(data[0]), Number(total));
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        const seriesValue = opts.w.globals.series[opts.seriesIndex];
        return `${calculatePercentage(seriesValue, total)}`;
      },
    },

    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, w }) {
        const percentage = calculatePercentage(series[seriesIndex], total);
        let value;
        switch (postfix) {
          case "kg":
            value = formatWeight(series[seriesIndex]);
            break;
          case "currency":
            value = formatCurrency(series[seriesIndex]);
            break;
          default:
            value = series[seriesIndex];
            break;
        }
        return `
          <div style="background-color: #000; color: #fff; padding: 5px; border-radius: 3px;">
            <span>${w.config.labels[seriesIndex]}: ${value} (${percentage})</span>
          </div>
        `;
      },
    },
    stroke: {
      show: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      labels: {
        colors: "var(--text-color)",
      },
      formatter: function (seriesName, opts) {
        let value;

        switch (postfix) {
          case "kg":
            value = formatWeight(opts.w.globals.series[opts.seriesIndex]);
            break;
          case "currency":
            value = formatCurrency(opts.w.globals.series[opts.seriesIndex]);
            break;
          default:
            value = opts.w.globals.series[opts.seriesIndex];
            break;
        }
        return `${seriesName}: <b>${value}</b>`;
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
  };

  return (
    <div className="donut-chart">
      <Chart
        options={chartOptions}
        series={adjustedData}
        type="donut"
        width={400}
      />
    </div>
  );
};

export default DonutChart;
