import React from "react";
import Chart from "react-apexcharts";
import { shortDays } from "../../common/constants";

const BarChart = ({ data }) => {
  const sortedDays = data?.categories?.map(
    (dayId) => shortDays.find((day) => day.id === dayId)?.name
  );

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: false,
      toolbar: { show: false },
    },
    colors: ["#ff00ff", "#53c41a", "#ffa500", "#f5222d"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: sortedDays ?? [],
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Math.floor(value);
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    tooltip: {
      y: {
        formatter: (value, { seriesIndex, series, dataPointIndex, w }) => {
          if (seriesIndex === 0) {
            return `${value}K`;
          }
          return `${value}`;
        },
      },
    },
  };

  const series = [
    {
      name: "Revenue",
      data: data?.revenueData ?? [],
    },
    {
      name: "Orders",
      data: data?.orderData ?? [],
    },
    {
      name: "Routes",
      data: data?.routeData ?? [],
    },
    {
      name: "Weight (T)",
      data: data?.weightData ?? [],
    },
  ];

  return (
    <Chart
      options={chartOptions}
      series={series}
      type="bar"
      width={"100%"}
      height={300}
      key="bar"
    />
  );
};

export default BarChart;
