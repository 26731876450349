import { Box } from "@mui/material";
import { Suspense, lazy } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom"; //HashRouter
import AutorenewIcon from "@mui/icons-material/Autorenew";
//lazy load components
import Layout from "./Layout";
import About from "./About";
import Contact from "./Contact";
import DownloadInvoices from "./DownloadInvoices";
import SignIn from "../pages/Signin/SignIn";
import SignUp from "../pages/Signup/SignUp";
import DriverList from "../pages/Drivers/DriverList";
import Driver from "../pages/Drivers/Driver";
import VehicleList from "../pages//Vehicles/VehicleList";
import Vehicle from "../pages/Vehicles/Vehicle";
import DataSync from "../pages/Routes/DataSync";
import RouteList from "../pages/Routes/RoutesList";
import RouteForm from "../pages/Routes/RouteForm";
import PalletReport from "../pages/Reports/PalletReport";
import PrintReports from "../pages/Reports/PrintReports";
import HelperList from "../pages/Helpers/HelperList";
import Helper from "../pages/Helpers/Helper";
import ProtectedRoute from "../ProtectedRoute";
import Roaster from "../pages/Roaster/Roaster";
import RoasterList from "../pages/Roaster/RoasterList";
import MaintenanceList from "../pages/Maintenance/MaintenanceList";
import Maintenance from "../pages/Maintenance/Maintenance";
import VehicleMaintenanceReport from "../pages/Reports/VehicleMaintenanceReport";
import EquipmentList from "../pages/Equipments/EquipmentList";
import Equipment from "../pages/Equipments/Equipment";
import EquipmentMaintenance from "../pages/EquipmentMaintenance/EquipmentMaintenance";
import AisleList from "../pages/Aisles/AisleList";
import Aisle from "../pages/Aisles/Aisle";
import LocationList from "../pages/Locations/LocationList";
import Location from "../pages/Locations/Location";
import ProductList from "../pages/Products/ProductList";
import Product from "../pages/Products/Product";
import PCN from "../pages/PCN/PCN";
import PCNlist from "../pages/PCN/PCNList";
import UserList from "../pages/Users/UserList";
import User from "../pages/Users/User";
import Fallback from "../pages/Fallback/Fallback";
import Login from "../pages/Signin/Login";
import Service from "../pages/Services/Service";
import Reminders from "../pages/Reminders/Reminders";
import ViewReports from "../pages/Reports/ViewReports";
import { useSelector } from "react-redux";
import AccidentList from "../pages/AccidentReport/AccidentList";
import Accident from "../pages/AccidentReport/Accident";
import NewAppLayout from "./Layout/NewAppLayout";
import DiscountReport from "../pages/Reports/DiscountReport";
import DefectReport from "../pages/Reports/DefectReport";
import FuelReport from "../pages/Reports/FuelReport";
import FileImport from "../pages/FileImports/FileImport";
import ServiceReport from "../pages/Reports/ServiceReport";
import LocationReport from "../pages/Reports/LocationReport";
import ContactReminder from "../pages/Reminders/ContactReminder";
import ContactReport from "../pages/Reports/ContactReport";
import AddServiceReminder from "../pages/Reminders/AddServiceReminder";
import EditServiceReminder from "../pages/Reminders/EditServiceReminder";
import ResolveServiceReminder from "../pages/Reminders/ResolveServiceReminder";
import DriverReminders from "../pages/Reminders/DriverReminders/DriverReminders";
import AddDriverReminder from "../pages/Reminders/DriverReminders/AddDriverReminder";
import HelperReminders from "../pages/Reminders/HelperReminders/HelperReminders";
import AddHelperReminder from "../pages/Reminders/HelperReminders/AddHelperReminder";
import EditDriverReminder from "../pages/Reminders/DriverReminders/EditDriverReminder";
import EditHelperReminder from "../pages/Reminders/HelperReminders/EditHelperReminder";
import ResolveDriverReminder from "../pages/Reminders/DriverReminders/ResolveDriverReminder";
import ResolveHelperReminder from "../pages/Reminders/HelperReminders/ResolveHelperReminder";
import SingleDiscountItem from "../pages/Reports/SignleDiscountItem";
import Home from "../pages/Dashboard/Home";
function App() {
  const { type }  = useSelector((state) => state.AppState?.userTypes);

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <Box
            style={{
              display: "block",
              width: "100%",
              textAlign: "center",
              paddingTop: "10%",
            }}
          >
            <AutorenewIcon />
          </Box>
        }
      >
        <Routes>
          {/* <Route path="/register" element={<SignUp />} /> */}
          <Route path="/" element={<Login />} />
          <Route
            path="*"
            element={
                <Fallback />
            }
          />
          <Route element={<NewAppLayout />}>
          { type === 0 && (
            <>
            <Route
              path="/home"
              onLeave={() => {
                alert("helloo dont go");
              }}
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/imports"
              element={
                <ProtectedRoute>
                  <FileImport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <UserList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/:id"
              element={
                <ProtectedRoute>
                  <User />
                </ProtectedRoute>
              }
            />
            {/* <Route
            path="*"
            element={
                <Fallback />
            }
          /> */}
            </>
          )}
          {type !== 0 && (
            <>
            <Route
              path="/home"
              onLeave={() => {
                alert("helloo dont go");
              }}
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/imports"
              element={
                <ProtectedRoute>
                  <FileImport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              
              element={
                <ProtectedRoute>
                  <DataSync />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contact"
              element={
                <ProtectedRoute>
                  <Contact />
                </ProtectedRoute>
              }
            />
            <Route
              path="/download-invoices"
              element={
                <ProtectedRoute>
                  <DownloadInvoices />
                </ProtectedRoute>
              }
            />
            <Route
              path="/about"
              element={
                <ProtectedRoute>
                  <About />
                </ProtectedRoute>
              }
            />
            <Route
              path="/aisles"
              element={
                <ProtectedRoute>
                  <AisleList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/aisle/:id"
              element={
                <ProtectedRoute>
                  <Aisle />
                </ProtectedRoute>
              }
            />
            <Route
              path="/locations"
              element={
                <ProtectedRoute>
                  <LocationList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/location/:id"
              element={
                <ProtectedRoute>
                  <Location />
                </ProtectedRoute>
              }
            />
            <Route
              path="/products"
              element={
                <ProtectedRoute>
                  <ProductList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/product/:id"
              element={
                <ProtectedRoute>
                  <Product />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/drivers"
              element={
                <ProtectedRoute>
                  <DriverList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/drivers/:id"
              element={
                <ProtectedRoute>
                  <Driver />
                </ProtectedRoute>
              }
            />
            <Route
              path="/helpers"
              element={
                <ProtectedRoute>
                  <HelperList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/helpers/:id"
              element={
                <ProtectedRoute>
                  <Helper />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles"
              element={
                <ProtectedRoute>
                  <VehicleList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicles/:id"
              element={
                <ProtectedRoute>
                  <Vehicle />
                </ProtectedRoute>
              }
            />
            <Route
              path="/equipments"
              element={
                <ProtectedRoute>
                  <EquipmentList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/equipments/:id"
              element={
                <ProtectedRoute>
                  <Equipment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/equipments/maintenance/:id"
              element={
                <ProtectedRoute>
                  <EquipmentMaintenance />
                </ProtectedRoute>
              }
            />
            <Route
              path="/maintenance"
              element={
                <ProtectedRoute>
                  <MaintenanceList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/maintenance/:id"
              element={
                <ProtectedRoute>
                  <Maintenance />
                </ProtectedRoute>
              }
            />
            <Route
              path="/routes"
              element={
                <ProtectedRoute>
                  <RouteList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/routes/:id"
              element={
                <ProtectedRoute>
                  <RouteForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rosters"
              element={
                <ProtectedRoute>
                  <RoasterList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/rosters/:id"
              element={
                <ProtectedRoute>
                  <Roaster />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pcn/:id"
              element={
                <ProtectedRoute>
                  <PCN />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pcn"
              element={
                <ProtectedRoute>

                  <PCNlist />
                </ProtectedRoute>
              }
            />
            <Route
              path="/accident/:id"
              element={
                <ProtectedRoute>
                  <Accident />
                </ProtectedRoute>
              }
            />
            <Route
              path="/accident"
              element={
                <ProtectedRoute>

                  <AccidentList />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/vehicle_service"
              element={
                <ProtectedRoute>
                  <Service />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicle_reminder"
              element={
                <ProtectedRoute>
                  <Reminders />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/vehicle_reminder"
              element={
                <ProtectedRoute>
                  <Reminders />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicle_reminder/new"
              element={
                <ProtectedRoute>
                  <AddServiceReminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicle_reminder/:id"
              element={
                <ProtectedRoute>
                  <EditServiceReminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicle_reminder/resolve/:id"
              element={
                <ProtectedRoute>
                  <ResolveServiceReminder />
                </ProtectedRoute>
              }
            />


              <Route
              path="/driver_reminder"
              element={
                <ProtectedRoute>
                  <DriverReminders />
                </ProtectedRoute>
              }
            />
            <Route
              path="/driver_reminder/new"
              element={
                <ProtectedRoute>
                  <AddDriverReminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/driver_reminder/:id"
              element={
                <ProtectedRoute>
                  <EditDriverReminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/driver_reminder/resolve/:id"
              element={
                <ProtectedRoute>
                  <ResolveDriverReminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/helper_reminder"
              element={
                <ProtectedRoute>
                  <HelperReminders />
                </ProtectedRoute>
              }
            />
            <Route
              path="/helper_reminder/new"
              element={
                <ProtectedRoute>
                  <AddHelperReminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/helper_reminder/:id"
              element={
                <ProtectedRoute>
                  <EditHelperReminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/helper_reminder/resolve/:id"
              element={
                <ProtectedRoute>
                  <ResolveHelperReminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contact_reminder"
              element={
                <ProtectedRoute>
                  <ContactReminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pallets_report"
              element={
                <ProtectedRoute>
                  <PalletReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/discounts"
              element={
                <ProtectedRoute>
                  <DiscountReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/discounts/:internal_id/:discount_type"
              element={
                <ProtectedRoute>
                  <SingleDiscountItem />
                </ProtectedRoute>
              }
            />
            <Route
              path="/fuel_report"
              element={
                <ProtectedRoute>
                  <FuelReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contact_report"
              element={
                <ProtectedRoute>
                  <ContactReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/service_report"
              element={
                <ProtectedRoute>
                  <ServiceReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/defect_report"
              element={
                <ProtectedRoute>
                  <DefectReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/location_report"
              element={
                <ProtectedRoute>
                  <LocationReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/print_reports"
              element={
                <ProtectedRoute>
                  <PrintReports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view_reports"
              element={
                <ProtectedRoute>
                  <ViewReports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/maintenance_report"
              element={
                <ProtectedRoute>
                  <VehicleMaintenanceReport />
                </ProtectedRoute>
              }
            />
            {/* <Route
            path="*"
            element={
                <Fallback />
            }
          /> */}
            </>
          )}
          
          </Route>
          
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
