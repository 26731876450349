import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Divider,
  Row,
  Statistic,
  Table,
  Switch,
  Button,
  Typography,
  Collapse,
  Popconfirm,
  Descriptions,
  Image,
  Tag,
  Input,
  Modal,
  DatePicker,
  message,
  Tooltip,
  Result,
} from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import { CheckCircleFilled, CheckOutlined, CloseCircleFilled, CloseOutlined, CloudDownloadOutlined, ExpandAltOutlined, EyeFilled } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import {
  cardTerminals,
  PostponeReasons,
  userRolesTypes,
} from "../../Components/common/constants";
import { useSelector } from "react-redux";
import { netsuite_url, tms_uploads_cdn, env } from "../../Components/config";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
// import { config } from "../../Components/config/";

const colors = ["#f0f0f0", "#e6f7ff", "#faf0e6", "#fcf8e3"];
const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    "& .ant-card .ant-card-body": {
      padding: "4px",
      // backgroundColor: 'rgb(240, 240, 240)',
    },
    "& .ant-table-thead>tr>th": {
      padding: "0px 4px !important"
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .ant-input[disabled]": {
      background: "none",
      color: "black",
      fontWeight: "400",
    },
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
}));
const { Text } = Typography;

const DriverSheetReport = ({
  driverSheetData,
  setDriverSheetData,
  paymentDataPost1,
  paymentAuthorizer,
}) => {
  const { roles } = useSelector((state) => state.AppState?.userTypes);
  const UserType = useSelector((state) => state.AppState?.userTypes?.type);
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  // const [disabledPost, setDisabledPost] = React.useState(false);
  const [disabledRoutes, setDisabledRoutes] = React.useState([]);
  const [screenWidth, setScreenWidth] = React.useState("");

  const [postData, setPostData] = React.useState(paymentDataPost1);

  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };


  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const generateColor = (index) => {
    const colors = ["#f0f0f0"];
    return colors[0];
  };
  const { Panel } = Collapse;

  const handleFilesOpen = (row) => {
    console.log("row", row);
    if (row) {
      setSelectedRecord(row);
    }
    setFilesOpen(true);
  };
  const handleFilesClose = () => setFilesOpen(false);

  const matchedRoles = roles
    .map((role) => {
      return userRolesTypes.find((userRole) => userRole.id === role.role_id);
    })
    .filter(Boolean);
  const includesFinance = matchedRoles.some((item) => item.title === "Finance");

  console.log('post', disabledRoutes);
  

  const dataCol = [
    {
      title: "S.No.",
      dataIndex: "rank",
      key: "rank",
      width: 55,
    },

    // {
    //   title: "Shop Code",
    //   dataIndex: "customerName",
    //   key: "customerName",
    //   width: 100
    // },
    {
      title: "Shop Code",
      dataIndex: "customerName",
      // key: "customerName",
      render: (text, record) => {
        return (
          <span>
            <a
              href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.customerInternalId}`}
              target="_blank"
            >
              {record.customerName}
            </a>
          </span>
        );
      },
      width: 100,
    },
    {
      title: "Shop Name",
      dataIndex: "customerProjectId",
      key: "customerProjectId",
      width: 200,
    },
    // {
    //   title: "Invoice",
    //   dataIndex: "invoice_no",
    //   key: "invoice_no",
    //   width: 100
    // },
    {
      title: "Invoice",
      // dataIndex: "invoice_no",
      render: (text, record) => {
        return (
          <span>
            <a
              href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.invoiceInternalId}`}
              target="_blank"
            >
              {record.invoice_no}
            </a>
          </span>
        );
      },
      width: 100,
    },
    {
      title: "Total (£)",
      dataIndex: "orderAmount",
      key: "orderAmount",
      render: (text, record) =>
        Number(record.orderAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || "0.00",
      width: 100,
    },
    {
      title: "Cash",
      children: [
        {
          title: "Driver",
          align: "center",
          key: "amountReceivedCash",
          render: (text, record) => ({
            props: {
              style: { background: "rgb(240, 240, 240)" },
            },
            children:
              Number(record.amountReceivedCash).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || "0.00",
          }),
          width: 75,
        },
        {
          title: "Post",
          align: "center",
          key: "amountReceivedCashInput",
          render: (text, record) => ({
            props: {
              style: { background: "" },
            },
            children: (
              <Input
                type="number"
                disabled={disabledRoutes.includes(record?.routeStatusID)}
                value={
                  postData[record.palletOrderID]?.amount_received_cash_post !== null &&
                  postData[record.palletOrderID]?.amount_received_cash_post !== undefined
                    ? postData[record.palletOrderID]?.amount_received_cash_post
                    : record.amountReceivedCash
                }
                onChange={(e) => {
                  const newValue = Math.max(0, e.target.value);
                  handlePostCashDataChange(e.target.value, record);
                }}
              />
            ),
          }),
          width: 100,
        },
      ],
      width: 300,
    },
    {
      title: "Card",
      children: [
        {
          title: "Driver",
          align: "center",
          key: "amountReceivedCard",
          render: (text, record) => ({
            props: {
              style: { background: "rgb(240, 240, 240)" },
            },
            children:
              Number(record.amountReceivedCard).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || "0.00",
          }),
          width: 75,
        },
        {
          title: "Post",
          align: "center",
          key: "amountReceivedCardInput",
          render: (text, record) => (
            <Input
              type="number"
              disabled={disabledRoutes.includes(record?.routeStatusID)}
              value={
                postData[record.palletOrderID]?.amount_received_card_post !== null &&
                postData[record.palletOrderID]?.amount_received_card_post !== undefined
                  ? postData[record.palletOrderID]?.amount_received_card_post
                  : record.amountReceivedCard
              }
              onChange={(e) => {
                const newValue = Math.max(0, e.target.value);
                handlePostCardDataChange(e.target.value, record);
              }}
            />
          ),
          width: 100,
        },
      ],
      width: 300,
    },
    {
      title: "Bank",
      children: [
        {
          title: "Driver",
          align: "center",
          key: "amountReceivedAccount",
          render: (text, record) => ({
            props: {
              style: { background: "rgb(240, 240, 240)" },
            },
            children:
              Number(record.amountReceivedAccount).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || "0.00",
          }),
          width: 75,
        },
        {
          title: "Post",
          align: "center",
          key: "amountReceivedAccountInput",
          render: (text, record) => (
            <Input
              type="number"
              disabled={disabledRoutes.includes(record?.routeStatusID)}
              value={
                postData[record.palletOrderID]?.amount_received_account_post !== null &&
                postData[record.palletOrderID]?.amount_received_account_post !== undefined
                  ? postData[record.palletOrderID]?.amount_received_account_post
                  : record.amountReceivedAccount
              }
              onChange={(e) => {
                const newValue = Math.max(0, e.target.value);
                handlePostAccountDataChange(e.target.value, record);
              }}
            />
          ),
          width: 100,
        },
      ],
      width: 300,
    },
    {
      title: "Status",
      render(record) {
        let statusText;
        let backgroundColor;

        switch (record?.delivered) {
          case 1:
            statusText = "Delivered";
            backgroundColor = "#80cb80";
            break;
          case 2:
            statusText = "Postponed";
            backgroundColor = "#f0d787";
            break;
          default:
            statusText = "Not Delivered";
            backgroundColor = "#edb1a7";
            break;
        }

        return {
          props: {
            style: {
              background: backgroundColor,
            },
          },
          children: <>{statusText}</>,
        };
      },
      width: 100,
    },
    {
      title: "Action",
      align: "center",
      width: 75,
      render(record) {
        return (
          <EyeFilled
            style={{ cursor: "pointer", fontSize: "25px" }}
            onClick={() => {
              handleFilesOpen(record);
            }}
          />
        );
      },
    },
  ];

  const [expandAll, setExpandAll] = useState(false);

  const onClickExpand = () => {
    let driverSheetData1 = [...driverSheetData];

    if (expandAll) {
      driverSheetData1.map((route, rIdx) => {
        route.expanded = false;
      });
    } else {
      driverSheetData1.map((route, rIdx) => {
        route.expanded = true;
      });
    }
    setDriverSheetData(driverSheetData1);
    setExpandAll(!expandAll);
  };

  const handleRouteExpand = (route_idx) => {
    const updatedData = [...driverSheetData];
    updatedData[route_idx].expanded = !updatedData[route_idx].expanded;
    setDriverSheetData(updatedData);
  };

  useEffect(() => {
    if (driverSheetData.length === 1) {
      setDriverSheetData((prevData) => {
        return prevData.map((item) => ({
          ...item,
          expanded: true,
        }));
      });
    }
  }, []);

  const downloadCSVFile = (id) => {
    const dataObject = driverSheetData.find((item, idx) => idx === id);
    console.log("dataObject", dataObject);

    // Extract formatted_date and driver fields
    const formattedDate = dataObject.formatted_date.replace(/ /g, "-"); // Replace spaces with dashes
    const driverName = dataObject.driver.replace(/\s+/g, ""); // Remove spaces

    // Define the filename
    const filename = `${formattedDate}_${dataObject.driverRoute}_DriverSheet.csv`;

    // Define fields to exclude from tableData
    const excludedTableDataFields = [
      "invoiceInternalId",
      "palletOrderID",
      "customerInternalId",
    ];

    // Define a mapping object to rename tableData headers
    const tableDataHeaderMapping = {
      rank: "S No.",
      customerName: "Shop Code",
      customerProjectId: "Shop Name",
      invoice_no: "Invoice No",
      orderAmount: "Total",
      amountReceivedCash: "Cash",
      amountReceivedCard: "Card",
      amountReceivedAccount: "Bank",
    };

    // Define simple fields mapping with the desired sequence
    const simpleFieldsMapping = {
      route: "Route No",
      driver: "Driver",
      truck: "Truck Reg.",
      driverRoute: "Route",
      terminal: "CC Terminal No.",
      totalCash: "Total Cash",
      totalCard: "Total Card",
      totalAccount: "Total Account",
    };

    // Define the desired sequence of simple fields
    const simpleFieldsSequence = [
      "route",
      "driver",
      "truck",
      "driverRoute",
      "terminal",
      "totalCash",
      "totalCard",
      "totalAccount",
    ];

    // Define the desired sequence of table fields
    const tableFieldsSequence = [
      "rank",
      "customerName",
      "customerProjectId",
      "invoice_no",
      "orderAmount",
      "amountReceivedCash",
      "amountReceivedCard",
      "amountReceivedAccount",
    ];

    // Extract all fields from the dataObject, excluding 'expanded' and 'formatted_date'
    const fields = Object.keys(dataObject).filter(
      (field) =>
        field !== "expanded" &&
        field !== "formatted_date" &&
        field !== "routeStatusID"
    );

    // Separate simple fields and tableData
    const tableDataField = fields.find((field) => field === "tableData");

    // Format data into CSV string
    let csvData = "";

    // Loop through each simple field in the desired sequence
    simpleFieldsSequence.forEach((field) => {
      if (dataObject[field] !== undefined) {
        let fieldName = simpleFieldsMapping[field] || field;
        let fieldValue = dataObject[field];

        // Concatenate 'C' to the terminal field value
        if (field === "terminal") {
          fieldValue = `C${fieldValue}`;
        }

        csvData += `${fieldName},${fieldValue}\n`;
      }
    });

    // Process tableData if it exists
    if (tableDataField) {
      // Add tableData headers to CSV in the desired sequence
      const renamedHeaders = tableFieldsSequence
        .filter((header) => !excludedTableDataFields.includes(header))
        .map((header) => tableDataHeaderMapping[header] || header);
      csvData += `${renamedHeaders.join(",")}\n`;

      // Loop through each row in tableData and add to CSV
      dataObject[tableDataField].forEach((row) => {
        // Add row data in the desired sequence
        const rowData = tableFieldsSequence
          .filter(
            (key) =>
              !excludedTableDataFields.includes(key) && row[key] !== undefined
          )
          .map((key) => row[key]);
        csvData += `${rowData.join(",")}\n`;
      });
    }

    // Create a Blob object from the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    // Append the link to the body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up: remove the link and revoke the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const genExtra = (idx, data) => {
    
    const posted = data?.payments_posted_by > 0 ? true : false;
  const completed = data?.start_status === 2 ? true : false;

    return (
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <Button
            style={{
              marginRight: '5px',
              color: completed  ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={completed ? <CheckOutlined/> : <CloseOutlined />}
              >
                Route Completed
          </Button>
        {
            <Button
              style={{
                marginRight: '5px',
                color: posted || disabledRoutes.includes(data.routeStatusID) ? 'green' : 'red',
                cursor: 'alias',
                pointerEvents: "none",
                padding: '4px 4px'
              }}
              icon={posted || disabledRoutes.includes(data.routeStatusID) ? <CheckOutlined/> : <CloseOutlined />}
                >
                  Payment Posted
            </Button>
          
        }
        {/* <Button
      type="primary"
      style={{padding: '4px 4px'}}
      onClick={(e) => {
        e.stopPropagation();
        downloadCSVFile(idx);
      }}
    >
      Download
    </Button> */}
    <Tooltip title="Download">
    <CloudDownloadOutlined
    style={{ fontSize: '25px', color: '#1677FF'}}
    onClick={(e) => {
      e.stopPropagation();
      downloadCSVFile(idx);
    }}
     />
     </Tooltip>
    </div>
    )
};

  // const genExtra = (id) => (
  //   <Button
  //     type="primary"
  //     size="large"
  //     onClick={(e) => {
  //       e.stopPropagation();
  //       downloadCSVFile(id);
  //     }}
  //   >
  //     Download
  //   </Button>
  // );

  // useEffect(() => {
  //   const storedDisabledRoutes =
  //     JSON.parse(localStorage.getItem("disabledRoutes")) || [];
  //   setDisabledRoutes(storedDisabledRoutes);
  // }, []);

  useEffect(() => {
    localStorage.setItem("disabledRoutes", JSON.stringify(disabledRoutes));
  }, [disabledRoutes]);

  useEffect(() => {
    // Retrieve previously stored disabledRoutes from localStorage
    const storedDisabledRoutes = JSON.parse(localStorage.getItem("disabledRoutes")) || [];
  
    // Check driverData and add routeStatusID to disabledRoutes if payments_posted_by > 1
    const newDisabledRoutes = driverSheetData
      .filter(driver => driver.payments_posted_by > 0)
      .map(driver => driver.routeStatusID);
  
    // Merge stored disabledRoutes with new ones, avoiding duplicates
    const updatedDisabledRoutes = [...new Set([...storedDisabledRoutes, ...newDisabledRoutes])];
  
    // Update the state and localStorage
    setDisabledRoutes(updatedDisabledRoutes);
    localStorage.setItem("disabledRoutes", JSON.stringify(updatedDisabledRoutes));
  }, [driverSheetData]);
  

  const handlePostCashDataChange = (value, record) => {
    const updatedDeliveryQuantities = {
      ...postData,
      [record.palletOrderID]: {
        ...postData[record.palletOrderID],
        amount_received_cash_post: value,
      },
    };
    setPostData(updatedDeliveryQuantities);
  };

  const handlePostCardDataChange = (value, record) => {
    const updatedDeliveryQuantities = {
      ...postData,
      [record.palletOrderID]: {
        ...postData[record.palletOrderID],
        amount_received_card_post: value,
      },
    };
    setPostData(updatedDeliveryQuantities);
  };

  const handlePostAccountDataChange = (value, record) => {
    const updatedDeliveryQuantities = {
      ...postData,
      [record.palletOrderID]: {
        ...postData[record.palletOrderID],
        amount_received_account_post: value,
      },
    };
    setPostData(updatedDeliveryQuantities);
  };

  const [selectedDates, setSelectedDates] = useState({});

  useEffect(() => {
    const initialDates = driverSheetData.reduce((acc, data, index) => {
      acc[index] = data?.postingDateInitial
        ? dayjs(data.postingDateInitial)
        : null;
      return acc;
    }, {});

    setSelectedDates(initialDates);
  }, [driverSheetData]);

  const handleDateChange = (date, index) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [index]: date,
    }));
  };

  console.log("selected Date", selectedDates);

  const postPayemnts = async (routeStatusID, index) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const selectedDate = selectedDates[index];
    const filterData = driverSheetData.filter(
      (obj) => obj.routeStatusID == routeStatusID
    );
    const payload = filterData.flatMap((route) =>
      route.tableData.map((item) => ({
        amount_received_cash_post:
          postData[item.palletOrderID]?.amount_received_cash_post ??
          item?.amountReceivedCash,
        amount_received_card_post:
          postData[item.palletOrderID]?.amount_received_card_post ??
          item?.amountReceivedCard,
        amount_received_account_post:
          postData[item.palletOrderID]?.amount_received_account_post ??
          item?.amountReceivedAccount,
        amount_received_cash: item?.amountReceivedCash,
        amount_received_card: item.amountReceivedCard,
        amount_received_account: item.amountReceivedAccount,
        inv_internal_id: item.invoiceInternalId,
      }))
    );
    const dataToSend = {
      route_status_id: routeStatusID,
      post_payments: payload,
      posting_date_ns: moment(new Date(selectedDate)).format("DD/MM/YYYY"),
      posting_date: moment(new Date(selectedDate)).format("YYYY-MM-DD"),
    };

    ShowActionDialog(
      "Are you sure to want to Post payments?",
      "confirm",
      true,
      async function () {
      showLoading(layoutDispatch);

        try {
          const response = await Auth.fetch("/route/update-payments-to-netsuite", {
            method: "POST",
            body: JSON.stringify(dataToSend),
          });
          if (response.ack) {
            setType("success");
            setLoadMessage(response.message + str);
            const updatedDisabledRoutes = [...disabledRoutes, routeStatusID];
            setDisabledRoutes(updatedDisabledRoutes);
            localStorage.setItem(
              "disabledRoutes",
              JSON.stringify(updatedDisabledRoutes)
            );
          } else {
            setType("error");
            setLoadMessage(response.message + str);
          }
        } catch (err) {
          console.log(err);
          message.error("Something went wrong, try again!");
        } finally {
          hideLoading(layoutDispatch)
        }
      
      }
    );
    
    
    console.log("payload", dataToSend);
    
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return <Tag color="warning">Pending</Tag>;
      case 2:
        return <Tag color="success">Approved</Tag>;
      case 3:
        return <Tag color="error">Rejected</Tag>;
      default:
        return "";
    }
  };

  useEffect(() => {
    message.config({
      top: window.innerHeight - 100,
      duration: 3,
    });
  }, []);

  return (
    <div className={classes.dataSheet}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>Driver Sheet</h3>
        <Button
          type="primary"
          style={{ float: "right" }}
          onClick={onClickExpand}
          icon={<ExpandAltOutlined />}
        >
          {expandAll ? "Collapse All" : "Expand All"}
        </Button>
      </div>
      {driverSheetData.length > 0 &&
        driverSheetData.map((data, index) => (
          <>
            <div className="view-page">
              <Collapse
                style={{ margin: "10px 0px" }}
                activeKey={data.expanded ? index.toString() : ""}
                onChange={() => {
                  handleRouteExpand(index);
                }}
              >
                <Panel
                  header={
                    <>
                      <span style={{ fontWeight: "bold" }}>Route: </span>
                      <span style={{ marginRight: "10px" }}>
                        {data.driverRoute}
                      </span>
                      <span style={{ fontWeight: "bold" }}>Driver: </span>
                      <span style={{ marginRight: "10px" }}>{data.driver}</span>
                      <span style={{ fontWeight: "bold" }}>Vehicle Reg: </span>
                      <span>{data.truck}</span>
                    </>
                  }
                  extra={genExtra(index, data)}
                  key={index}
                >
                  <Row gutter={[6, 16]}>
                    <Col span={3}>
                      <Card style={{ backgroundColor: generateColor(index) }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Statistic
                            title="Route No"
                            value={data.route}
                            valueStyle={{
                              color: "#3f8600",
                            }}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col span={3}>
                      <Card style={{ backgroundColor: generateColor(index) }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Statistic
                            title="Truck"
                            value={data.truck}
                            valueStyle={{
                              color: "#3f8600",
                            }}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card style={{ backgroundColor: generateColor(index) }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Statistic
                            title="Route"
                            value={data.driverRoute}
                            valueStyle={{
                              color: "#3f8600",
                            }}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card style={{ backgroundColor: generateColor(index) }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Statistic
                            title="Driver"
                            value={data.driver}
                            valueStyle={{
                              color: "#3f8600",
                            }}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col span={4}>
                      <Card style={{ backgroundColor: generateColor(index) }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Statistic
                            title="CC Terminal No."
                            value={
                              data.terminal
                                ? cardTerminals.filter(
                                    (obj) => obj.id == data.terminal
                                  )[0].title
                                : "N/A"
                            }
                            valueStyle={{
                              color: "#3f8600",
                            }}
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={[6, 16]} style={{ marginTop: "10px" }}>
                    <Col span={3}>
                      <Card style={{ backgroundColor: generateColor(index) }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Statistic
                            title="Total Cash"
                            value={"£" + data.totalCash.toFixed(2)}
                            precision={2}
                            valueStyle={{
                              color: "#3f8600",
                            }}
                            // prefix="£"
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col span={3}>
                      <Card style={{ backgroundColor: generateColor(index) }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Statistic
                            title="Total Card"
                            value={"£" + data.totalCard.toFixed(2)}
                            precision={2}
                            valueStyle={{
                              color: "#3f8600",
                            }}
                            // prefix="£"
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col span={3}>
                      <Card style={{ backgroundColor: generateColor(index) }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Statistic
                            title="Total Bank"
                            value={"£" + data.totalAccount.toFixed(2)}
                            precision={2}
                            valueStyle={{
                              color: "#3f8600",
                            }}
                            // prefix="£"
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row
                    gutter={[6, 16]}
                    style={{
                      marginTop: "10px",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    {(UserType == 1 || includesFinance) && (
                      <>
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          style={{ width: 160, marginRight: "10px" }}
                          //  value={data?.postingDateInitial ? dayjs(data?.postingDateInitial) : null}
                          value={
                            selectedDates[index]
                              ? dayjs(selectedDates[index])
                              : null
                          }
                          onChange={(_, dateStr) => {
                            handleDateChange(dateStr, index);
                          }}
                          placeholder="Select Date"
                          disabled={
                            data.payments_posted_by > 0 ||
                            disabledRoutes.includes(data.routeStatusID)
                          }
                        />
                      </>
                    )}
                    {!disabledRoutes.includes(data.routeStatusID) && (
                      <>
                        {(UserType == 1 || includesFinance) && (
                          <>
                              <Button
                                disabled={
                                  data.payments_posted_by > 0 ||
                                  disabledRoutes.includes(data.routeStatusID)
                                }
                                onClick={() => postPayemnts(data.routeStatusID, index)}
                                type="primary"
                              >
                                Post payments to Netsuite
                              </Button>
                          </>
                        )}
                      </>
                    )}

                    {(data.payments_posted_by > 0 ||
                      disabledRoutes.includes(data.routeStatusID)) && (
                      <Link
                        to={
                          env === "uat"
                            ? `https://5615243-sb1.app.netsuite.com/app/common/search/searchresults.nl?searchtype=Transaction&CUSTBODY_TMS_BATCH_ID=${data.routeStatusID}&style=NORMAL&CUSTBODY_TMS_BATCH_IDtype=STARTSWITH&report=&grid=&searchid=2707&dle=F&sortcol=Transction_ORDTYPE9_raw&sortdir=ASC&csv=HTML&OfficeXML=F&pdf=&size=1000`
                            : `https://5615243.app.netsuite.com/app/common/search/searchresults.nl?searchid=2760&whence=&&CUSTBODY_TMS_BATCH_ID=${data.routeStatusID}&style=NORMAL&CUSTBODY_TMS_BATCH_IDtype=STARTSWITH&report=&grid=&searchid=2707&dle=F&sortcol=Transction_ORDTYPE9_raw&sortdir=ASC&csv=HTML&OfficeXML=F&pdf=&size=1000`
                        }
                        target="_blank"
                        style={{ marginLeft: "5px" }}
                      >
                        View Netsuite Payments
                      </Link>
                    )}
                  </Row>
                  
                  <>
                    <div className={classes.antTable}>
                      <Table
                        style={{ overflowX: "auto", marginTop: "10px" }}
                        columns={dataCol}
                        bordered
                        dataSource={data.tableData}
                        pagination={false}
                        scroll={{
                          y: 400,
                        }}
                      />
                    </div>
                  </>
                </Panel>
              </Collapse>
            </div>
          </>
        ))}
      <Message type={type} msg={loadMessage} />
      <Modal
        open={filesOpen}
        onCancel={handleFilesClose}
        okButtonProps={{ style: { display: "none" } }}
        title="Order Details"
        cancelText="Close"
        width={900}
      >
        <>
  {selectedRecord &&
  (
    selectedRecord.postponed_status ||
    (selectedRecord.postponed_files && selectedRecord.postponed_files.length > 0) ||
    selectedRecord.postponed_notes ||
    selectedRecord.received_by ||
    selectedRecord.notes ||
    selectedRecord.delivery_discount_req_status ||
    selectedRecord.delivery_discount_auth_by ||
    selectedRecord.payment_req_status ||
    selectedRecord.payment_auth_by ||
    selectedRecord.signature ||
    (selectedRecord.files && selectedRecord.files.length > 0)
  ) ? (
    <>
      {selectedRecord?.postponed_status ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Postponement Status"
            labelStyle={{ width: "200px" }}
          >
            {PostponeReasons.find((obj) => obj.id === selectedRecord.postponed_status)?.title || ""}
          </Descriptions.Item>
        </Descriptions>
      ) : null}

      {selectedRecord?.postponed_notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Postpone Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.postponed_notes || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.received_by && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Receiver Name"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord.received_by || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.notes || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.delivery_discount_req_status && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Discount"
            labelStyle={{ width: "200px" }}
          >
            {getStatusText(selectedRecord.delivery_discount_req_status)}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.delivery_discount_auth_by && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Auth By"
            labelStyle={{ width: "200px" }}
          >
            {(() => {
              const authorizer = paymentAuthorizer?.find(
                (obj) =>
                  obj?.id?.toString() ===
                  selectedRecord?.delivery_discount_auth_by?.toString()
              );
              return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
            })()}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.payment_req_status && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Discount"
            labelStyle={{ width: "200px" }}
          >
            {getStatusText(selectedRecord.payment_req_status)}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.payment_auth_by > 0 && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Auth By"
            labelStyle={{ width: "200px" }}
          >
            {(() => {
              const authorizer = paymentAuthorizer?.find(
                (obj) => obj?.id === selectedRecord?.payment_auth_by
              );
              return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
            })()}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.signature != null && (
        <>
          <h4>Signature:</h4>
          <div
            style={{
              position: "relative",
              marginRight: "10px",
              display: "inline-block",
            }}
          >
            <Image
              width={150}
              height={100}
              style={{ padding: "5px", border: "1px solid lightgrey" }}
              src={selectedRecord?.signature}
            />
          </div>
        </>
      )}

      <Divider />

      {selectedRecord?.files?.length > 0 && (
        <>
          <h4>Files</h4>
          <Image.PreviewGroup>
            {selectedRecord.files.map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/route_orders/${selectedRecord?.routeStatusID}/${item}`;
              return (
                <div
                  key={item}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                </div>
              );
            })}
          </Image.PreviewGroup>
        </>
      )}
      {selectedRecord?.postponed_files?.length > 0 && (
        <>
          <h4>Postpone Files</h4>
          <Image.PreviewGroup>
            {selectedRecord.postponed_files.map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/postpone_order/${selectedRecord?.routeStatusID}/${item}`;
              return (
                <div
                  key={item}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                </div>
              );
            })}
          </Image.PreviewGroup>
        </>
      )}
    </>
  ) : (
    <Result status="404" subTitle="Sorry, no data data to show." />
  )}
</>
      </Modal>
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
};

export default DriverSheetReport;
