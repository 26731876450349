import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RouteIcon from "@mui/icons-material/Route";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Card, Col, Row, Skeleton, Statistic as Stats } from "antd";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  formatCurrency,
  formatWeight,
} from "../../Components/common/CommonFun";
import _ from "lodash";

const Statistic = ({ total, loading, totalVehicle, vehicleLoading }) => {
  const initialStatsData = [
    {
      id: "ordersValue",
      title: "Revenue",
      value: formatCurrency(total?.revenue || 0),
      color: "magenta",
      order: 1,
    },
    {
      id: "orders",
      title: "Orders",
      value: total?.orders || 0,
      icon: <ShoppingCartIcon className="icon" />,
      color: "#52c41a",
      order: 2,
    },
    {
      id: "routes",
      title: "Routes",
      value: total?.routes || 0,
      icon: <RouteIcon className="icon" />,
      color: "#FFA500",
      order: 3,
    },
    {
      id: "availableVehicles",
      title: "Available Vehicles",
      value: totalVehicle || 0,
      icon: <LocalShippingIcon className="icon" />,
      color: "#1890ff",
      order: 4,
    },
    {
      id: "weight",
      title: "Weight",
      value: formatWeight(total?.weight || 0),
      icon: <FitnessCenterIcon className="icon" />,
      color: "#f5222d",
      order: 5,
    },
  ];

  const [statsData, setStatsData] = useState(initialStatsData);

  const updateStatsData = () => {
    const updatedData = statsData.map((stat) => {
      if (stat.id === "ordersValue") {
        return { ...stat, value: formatCurrency(total?.revenue || 0) };
      }
      if (stat.id === "orders") {
        return { ...stat, value: total?.orders || 0 };
      }
      if (stat.id === "routes") {
        return { ...stat, value: total?.routes || 0 };
      }
      if (stat.id === "weight") {
        return { ...stat, value: formatWeight(total?.weight || 0) };
      }
      if (stat.id === "availableVehicles") {
        return { ...stat, value: totalVehicle || 0 };
      }
      return stat;
    });
    setStatsData(updatedData);
  };

  useEffect(() => {
    updateStatsData();
  }, [total, totalVehicle]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedItems = Array.from(statsData);
    const [moved] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, moved);

    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      order: index + 1,
    }));

    setStatsData(updatedItems);
  };

  const sortItems = (items) => {
    return _.sortBy(items, "order");
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="stats" direction="horizontal">
        {(provided) => (
          <Row
            gutter={16}
            style={{ textAlign: "center" }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {sortItems(statsData).map((stat, index) => (
              <Draggable key={stat.id} draggableId={stat.id} index={index}>
                {(provided) => (
                  <Col
                    span={
                      stat.title === "Weight" ||
                      stat.title === "Available Vehicles"
                        ? 6
                        : 4
                    }
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Card>
                      {(loading && stat.id !== "availableVehicles") ||
                      (vehicleLoading && stat.id === "availableVehicles") ? (
                        <Skeleton
                          active
                          title={false}
                          paragraph={{ rows: 2 }}
                        />
                      ) : (
                        <Stats
                          title={stat.title}
                          value={stat.value}
                          prefix={
                            <span style={{ color: stat.color }}>
                              {stat?.icon}
                            </span>
                          }
                          valueStyle={{ color: stat.color }}
                        />
                      )}
                    </Card>
                  </Col>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Row>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Statistic;
